import NotFoundPage from 'src/blocks/ErrorPages/NotFound'
import BusinessLayout from 'src/layout/BusinessLayout/BusinessLayout'

export default function Custom404() {
  return (
    <NotFoundPage
      action={{
        label: 'Go Home',
        url: '/',
      }}
    />
  )
}

Custom404.getLayout = (page: React.ReactElement) => {
  return <BusinessLayout>{page}</BusinessLayout>
}
